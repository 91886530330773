import { styled, css } from "styled-components";
import theme from "styled-theming";

import Button from "components/Button";
import Icon from "components/Icon";
import { Container } from "style/components/Page";
import { styleBodyL, styleHeadingL, styleHeadingM } from "style/components/Typography";
import { columnGap } from "style/grid";
import { scrollMarginTopPad } from "style/headerOffsets";
import { MQ } from "style/mediaQueries";

const buttonColor = theme("theme", {
    undefined: "--color-blue",
    lightgray: "--color-blue",
    blue: "--color-cyan-l60",
    cyan: "--color-blue",
});

const stepNumberBackgroundColor = theme("theme", {
    undefined: "--color-black-195",
    lightgray: "--color-black-l95",
    blue: "--color-blue-d10",
    cyan: "--color-cyan-l80",
})

const circularColor = theme("theme", {
    lightgray: "--icon-on-neutral-disabled",
    blue: "--icon-on-blue-disabled",
    cyan: "--icon-on-cyan-disabled",
});

const indicatorBackgroundActive = theme("theme", {
    lightgray: "--icon-on-neutral-primary",
    blue: "--icon-on-blue-primary",
    cyan: "--icon-on-cyan-primary",
});

const buttonDisabledColor = theme("theme", {
    undefined: "--color-blue-o60",
    lightgray: "--color-blue-o60",
    blue: "--color-cyan-l60-o60",
    cyan: "--color-blue-o60",
});

const stepNumberColor = theme("theme", {
    undefined: "--color-cyan-l60",
    lightgray: "--color-cyan-l60",
    blue: "--color-blue",
    cyan: "--color-cyan-l60",
});

const borderColor = theme("theme", {
    undefined: "--color-black-o15",
    lightgray: "--color-black-o15",
    cyan: "--color-blue-o15",
    blue: "--color-cyan-o40",
});

export const StepButton = styled.button`
    all: unset;
    ${styleHeadingL};
    border-bottom: 1px solid var(${borderColor});
    border-top: 1px solid var(${borderColor});
    box-sizing: border-box;
    color: var(${buttonColor});
    cursor: pointer;
    display: flex;
    justify-content: center;
    width: 100%;

    &:disabled {
        color: var(${buttonDisabledColor});
        cursor: default;
    }

    &:focus-visible {
        outline: 7px solid var(--button-focus);
        outline-offset: 0;
    }
    ${theme("formGuide", {
        true: css`
            border: none;
            ${styleHeadingM};
        `
    })}
`;

export const StepButtonContent = styled(Container)`
    align-items: center;
    display: flex;

    ${theme("formGuide", {
        false: css`
            padding-bottom: 0.4375rem;
            padding-top: 0.4375rem;

            ${MQ.FROM_M} {
                padding-bottom: 0.875rem;
                padding-top: 0.875rem;
            }

            ${MQ.FROM_XL} {
                padding-bottom: 1.3125rem;
                padding-top: 1.3125rem;
            }
        `
    })}

    ${theme("formGuide", {
        true: css`
            padding-bottom: 0.875rem;
            padding-left: 2.625rem;
            padding-top: 1.75rem;

            ${MQ.FROM_M} {
                padding-left: 4.375rem;
            }
        `
    })}
`;

export const StepNumber = styled.div`
    ${styleBodyL};
    align-items: center;
    aspect-ratio: 1;
    border: 1px solid var(${buttonColor});
    border-radius: 50%;
    display: inline-flex;
    font-weight: bold;
    justify-content: center;
    margin-right: 0.875rem;
    transition:
        color 0.2s ease-in-out,
        background-color 0.2s ease-in-out;
    vertical-align: middle;
    width: 2.625rem;
    ${theme("formGuide", {
        true: css`
            left: -1.3125rem;
            position: absolute;
            top: 1.3125rem;

            ${MQ.FROM_M} {
                left: -1.6875rem;
            }
        `
    })}
    ${theme("open", {
        true: css`
            background: var(${buttonColor});
            color: var(${stepNumberColor});
        `,
        false: css`
            background: var(${stepNumberBackgroundColor});
            color: var(${buttonColor});
        `,
    })};
    ${theme("active", {
        false: css`
            background: var(${stepNumberBackgroundColor});
            border-color: var(${buttonDisabledColor});
            color: var(${buttonDisabledColor});
        `,
    })};

    ${MQ.FROM_M} {
        margin-right: 1.3125rem;
        width: 3.5rem;
    }

    ${MQ.FROM_XL} {
        margin-right: 1.75rem;
        width: 4.375rem;
        ${theme("formGuide", {
            true: css`
                width: 3.5rem;
            `
        })}
    }
`;

export const Step = styled.div`
    ${scrollMarginTopPad}
    align-items: center;
    display: flex;
    flex-direction: column;
    width: 100%;

    &.has-step.closed + .has-step {
        ${StepButton} {
            border-top: none;
        }
    }

    ${theme("formGuide", {
        true: css`
            border-left: 1px solid var(${circularColor});
            grid-column: col-start 1 / span 12;
            left: 1.3125rem;
            margin-bottom: 1.75rem;
            position: relative;

            &:first-child {
                margin-top: 7.875rem;
            }

            ${MQ.FROM_M} {
                grid-column: col-start 7 / span 6;
                left: 1.6875rem;
            }
        `
    })}
    ${theme("formGuide" && "active", {
        true: css`
            border-color: var(${indicatorBackgroundActive});
        `
    })}
`;

export const Buttons = styled.div`
    display: flex;
    flex-direction: row;
    gap: 0.875rem;
    margin-top: 3.5rem;

    ${MQ.FROM_M} {
        gap: 1.3125rem;
        margin-top: 4.375rem;
    }

    ${MQ.FROM_XL} {
        gap: 1.75rem;
        margin-top: 6.125rem;
    }
`;

export const Check = styled(Icon)`
    align-self: center;
    margin-left: auto;
`;

export const NavButton = styled(Button)`
    justify-self: flex-start;
`;

export const FormElements = styled(Container)`
    ${columnGap};
    display: ${theme("open", {
        true: "grid",
        false: "none"
    })};
    grid-template-columns: repeat(4, [col-start] 1fr);
    padding-bottom: 3.5rem;
    padding-top: 1.3125rem;
    width: 100%;

    ${MQ.FROM_M} {
        grid-template-columns: repeat(12, [col-start] 1fr);
        padding-bottom: 4.375rem;
        padding-top: 2.1875rem;
    }

    ${MQ.FROM_XL} {
        padding-bottom: 6.125rem;
        padding-top: 2.625rem;
    }

    ${theme("formGuide", {
        true: css`
            display: block;
            margin-bottom: 3.5rem;
            padding-bottom: 0;
            padding-left: 2.625rem;
            padding-top: 0;

            ${MQ.FROM_M} {
                padding-left: 4.375rem;
            }

            ${MQ.FROM_XL} {
                padding-bottom: 0;
                padding-top: 0;
            }
        `
    })}

    & > div {
         ${theme("formGuide", {
            false: css`
                grid-column: col-start 1 / span 4;
                ${MQ.FROM_M} {
                    grid-column: col-start 5 / span 8;
                }

                ${MQ.FROM_L} {
                    grid-column: col-start 6 / span 7;
                }

                ${MQ.FROM_XL} {
                    grid-column: col-start 6 / span 6;
                }
            `
        })}
        ${theme("formGuide", {
            true: css`
                grid-column: col-start 1 / span 12;
            `
        })}
    }
`;
